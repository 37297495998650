<template>
  <div class="search">
    <div class="main">
      <div class="title">诉前调解查询</div>
      <!-- <div class="sub-title">全国统一查询入口</div> -->
    </div>
    <div class="data-container" v-if="detailList.length">
      <div
        class="detail-container"
        v-for="(item, index) in detailList"
        :key="index"
      >
        <div class="detail">
          <div class="title">案件详情</div>
          <ul>
            <li>
              <span class="label">纠纷类别：</span>
              <span class="value">{{ item.disputeType }}</span>
            </li>
            <li v-if="item.end">
              <span class="label">调解状态：</span>
              <span class="value">{{ item.adjustState }}</span>
            </li>
            <li v-if="!item.end" style="display: block">
              <div class="wrapper">
                <div>
                  <span class="label">调解金额：</span>
                  <span class="value">{{ item.adjustAmount }}</span>
                </div>
                <span class="link" @click="uploadFiles(item)">我已还款>></span>
              </div>
              <div class="tip" style="text-align: left">
                <div>{{ item.adjustAmountDescription }}</div>
                {{ item.repayVoucherInfo }}
              </div>
              <div
                class="tip"
                style="text-align: left; font-style: italic; color: #0091ae"
              >
                {{ item.repaymentInfo }}
              </div>
            </li>
            <!-- <li>
              <span class="label">调解机构：</span>
              <span class="value">{{ item.adjustOrg }}</span>
            </li> -->
            <!-- <li v-if="item.adjustNo">
              <span class="label">委派号：</span>
              <span class="value">{{ item.adjustNo }}</span>
            </li> -->
            <!-- <li v-if="!item.end">
              <span class="label">调解员：</span>
              <span class="value">{{ item.adjustUser }}</span>
            </li>
            <li v-if="!item.end">
              <span class="label">调解员电话：</span>
              <span class="value">{{ item.adjustUserMobile }}</span>
            </li>
            <li>
              <span class="label">申请人：</span>
              <span class="value">{{ item.applicant }}</span>
            </li> -->
            <li style="border: 0; padding-bottom: 0">
              <span class="label">纠纷简要情况：</span>
            </li>
            <!-- <li>
              <span class="value">{{ item.description }}</span>
            </li> -->
            <li>
              <span class="value"
                >{{ item.description }}
                <div
                  v-if="item.description"
                  class="contact"
                  @click="showContactModal(item)"
                >
                  联系调解员
                </div>
              </span>
            </li>
          </ul>
          <!-- <div class="img-wrapper" v-if="item.mediationAppointmentLetter">
            <div class="title">委派调解函</div>
            <img
              :src="item.mediationAppointmentLetter.url"
              @click="getImg([item.mediationAppointmentLetter.url], index)"
            />
          </div>
          <div class="img-wrapper" v-if="item.civilComplaint">
            <div class="title">民事起诉状</div>
            <img
              :src="item.civilComplaint.url"
              @click="getImg([item.civilComplaint.url], index)"
            />
          </div>
          <div class="img-wrapper" v-if="item.hiveBook">
            <div class="title">聘请书</div>
            <img
              :src="item.hiveBook.url"
              @click="getImg([item.hiveBook.url], index)"
            />
          </div> -->
          <div class="detail" v-if="item.productRepayChannel">
            <div class="title">还款渠道</div>
            <ul>
              <li>
                <span class="label" style="min-width: 60px">户名：</span>
                <span class="value">{{
                  item.productRepayChannel.cardHolder
                }}</span>
              </li>
              <li>
                <span class="label">银行卡号:</span>
                <span class="value">{{ item.productRepayChannel.cardNo }}</span>
              </li>
              <li>
                <span class="label">开户行:</span>
                <span class="value">{{
                  item.productRepayChannel.cardName
                }}</span>
              </li>
              <li>
                <span class="label">说明:</span>
                <span class="value">{{ item.productRepayChannel.memo }}</span>
              </li>
            </ul>
          </div>
          <div class="img-wrapper" v-if="item.courtNotice">
            <div class="title">法院通知书</div>
            <img
              :src="item.courtNotice.url"
              @click="getImg([item.courtNotice.url], index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <div v-if="!disabledLink" style="font-size: 20px">
        没有您的信息，或者您的案件已经撤案！
      </div>
      <div style="padding: 50px 20px 0 20px; font-size: 18px" v-else>
        链接已经过期，请关注防城港市港口区人民法院公众号-司法公开-诉前调解查询，查询你的案件信息。
      </div>
      <div style="padding: 50px 20px 0 20px; font-size: 12px">
        防城港市港口区人民法院特邀调解员统一使用广西号码，任何其他地区座机均为诈骗，谨防上当！如有疑问请向港口法院核实调解员身份，核实电话：0770-2860805
      </div>
    </div>
    <div class="footer">
      <div>防城港市港口区人民法院：0770-2860805</div>
      <!-- <div class="mid">备案号:浙ICP备19038363号</div>
      <div>技术支持:杭州天迹信息技术服务有限公司</div> -->
    </div>
  </div>
</template>

<script>
import {
  gangkouLoanDetail,
  connectMediator,
  gkMpLogin,
} from "../../services/api";
import { ImagePreview, Dialog, Toast } from "vant";
import { getphoneModel } from "../../untils/model";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      detailList: [],
      photoList: [],
      disabledLink: false,
    };
  },

  mounted() {
    const phoneModel = getphoneModel(navigator.userAgent);
    if (this.$route.query.clientId) {
      gkMpLogin({
        clientId: this.$route.query.clientId,
      }).then((res) => {
        if (res.success) {
          this.disabledLink = false;
          if (res.result.tsToken) {
            localStorage.setItem("tsToken", res.result.tsToken);
            this.getGangkouLoanDetail(phoneModel);
          } else {
            Toast("该链接已失效");
          }
        } else {
          this.disabledLink = true;
          Toast("该链接已失效");
        }
      });
    } else {
      this.getGangkouLoanDetail(phoneModel);
    }
  },
  methods: {
    getGangkouLoanDetail(phoneModel) {
      gangkouLoanDetail({
        tsToken: localStorage.getItem("tsToken"),
        phoneModel: phoneModel || "",
      }).then((res) => {
        if (res.success) {
          this.detailList = res.result;
        }
      });
    },
    getImg(images, index) {
      ImagePreview({
        images,
        showIndex: false,
        loop: true,
        startPosition: index,
      });
    },
    uploadFiles(item) {
      this.$router.push({
        name: "UploadFile",
        query: { loanId: item.loanId, adjustAmount: item.adjustAmount },
      });
    },
    showContactModal(item) {
      connectMediator({
        mpSource: "防城港市港口区人民法院公众号",
        loanId: item.loanId,
        tsToken: localStorage.getItem("tsToken"),
      }).then((res) => {
        if (res.success) {
          Dialog.alert({
            message:
              "申请调解的请求已提交，调解员会在1个工作日内电话联系你，请注意接听。",
          }).then(() => {
            // on close
          });
        } else {
          Toast(res.message);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>
